import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"

class PageTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    const specialClass = 'simple-page'

    return (
      <Layout location={this.props.location} title={frontmatter.title} specialClass={specialClass}>
        <SEO
          title={markdownRemark.frontmatter.title}
        />
        <section className="hidden"></section>
        <section>
          <div className="page-section container">
            <div className="">
              <div
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
